import { DiJsBadge } from "react-icons/di";

const js = {
  name: "JavaScript",
  shortName: "JS",
  icon: DiJsBadge,
  color: "#ddb842",
};

export default js;
