import { DiReact } from "react-icons/di";

const react = {
  name: "React",
  shortName: "React",
  icon: DiReact,
  iconSize: 22,
  color: "#61DAFB",
};

export default react;
