import { DiFirebase } from "react-icons/di";

const firebase = {
  name: "Firebase",
  shortName: "Firebase",
  icon: DiFirebase,
  iconSize: 23,
  color: "#FFA714",
};

export default firebase;
